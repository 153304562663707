.show{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:  100%;
  background-color: rgba(0,0,0,.85);
  cursor: pointer;
}
.overlay {
  position: absolute;
  padding: 10px;
  top: 30px;
  left: 25%;
  right: 25%;
  white-space: no-wrap;
  z-index: 1;
  color: rgb(168, 144, 184);
}
.hide{
  display: none;
}