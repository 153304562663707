.title {
  text-align: center;
  color: rgb(116, 28, 33);
  font-weight: bold;
}

.register-btn {
  border-radius: 5px;
}

.description {
  width: 75%;
  margin: 0 auto 15px auto;
}

.content {
  text-align: center;
}

.auth-btn-container {
  display: flex;
  justify-content: center;
}

.auth-btn {
  height: 30px;
  width: 80px;
  padding-top: 7px;
  margin: 0 5px;
}
