.nav-bar {
  /* background: #838dfa; */
  /* padding: 10px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin: 0 10px;
}

.logout {
  border-radius: 100%;
  background-color: rgba(60, 88, 80);
  text-align: center;
  color: rgb(241, 184, 185);
  font-weight: bold;
  font-variant: small-caps;
  padding: 18px 5px;
  width: 52px;
}

.navButton {
  background-color: rgba(131, 141, 250, 0);
  color: black;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border: 0px;
  margin: 10px 0;
  font-weight: bold;
  cursor: pointer;
}
.home {
  font-weight: bold;
}
.logo {
  height: 45px;
  color: rgb(0, 0, 0, 0);
  background-color: rgba(60, 88, 80);
  padding: 5px 0;
  /* border: 1px solid black; */
  border-radius: 100%;
}

#infoOverlay {
  background-color: rgba(60, 88, 80);
  text-align: center;
  color: rgb(241, 184, 185);
  font-weight: bold;
  font-variant: small-caps;
  border-radius: 5px;
}
