.chat-area-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
}
.tools-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.chat-area {
  display: block;
  height: 600px;
  overflow: auto;
  border: 1px solid grey;
  border-radius: 5px;
  margin: 0;
  padding: 10px 5px;
  background-color: rgba(0, 0, 0, 0.1);
}
ul {
  list-style-type: none;
}

.active-users {
  display: inline;
}

.user {
  font-weight: bold;
  border-radius: 0.4em;
  clear: both;
  max-width: 80%;
}

.message {
  max-width: 100%;
  overflow-wrap: break-word;
  line-height: 1.4;
  margin-bottom: 5px;
}
.me {
  float: right;
}

.my-mess {
  color: rgb(102, 110, 193);
  float: right;
}
.partner-mess {
  float: left;
  color: rgb(255, 0, 162);
}
#leave-chatroom {
  width: 85px;
  height: 30px;
}

.input-flex-container {
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: space-between;
  margin: 20px 10px;
}
.write-message,
.message-btn {
  margin: 0;
  height: 30px;
}

@media only screen and (max-width: 600px) {
  .chat-area {
    margin: 0 10px;
  }
}
