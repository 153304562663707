.dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
}

.dashboard-content {
  margin-top: 15px;
  margin-bottom: 20px;
  width: 90%;
  text-align: center;
  font-size: 1.5em;
}
