.timer {
  font-size: 1.3em;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
  justify-self: left;
  height: 40px;
  border-radius: 5px;
}
.warning {
  color: red;
  font-weight: bold;
}
