.questions-area {
  height: 100px;
  display: flex;
  flex-flow: column;
  margin-top: 10px;
  align-items: center;
}
.questions {
  font-weight: bold;
  margin-bottom: 10px;
}
.question-btns {
  display: flex;
}

.send {
  margin: 0 10px;
}
