.login-form {
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
  margin: 0 auto 10px auto;
}
label {
  align-self: flex-start;
  font-size: 16px;
  margin-top: 10px;
}
input {
  width: 100%;
  padding: 8px 20px;
  margin: 0 auto;
  border: 1px solid rgb(109, 109, 109);
  border-radius: 4px;
}
input[type='text'],
[type='password'] {
  font-size: 18px;
}

.login-btn {
  margin-top: 10px;
  align-self: center;
  width: 80px;
}
.lds-spinner {
  margin: 10px auto;
}
