@import url(https://fonts.googleapis.com/css?family=Josefin+Sans|Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.messages{
  /* width: 80%; */
}
.timer {
  font-size: 1.3em;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
  justify-self: left;
  height: 40px;
  border-radius: 5px;
}
.warning {
  color: red;
  font-weight: bold;
}

.questions-area {
  height: 100px;
  display: flex;
  flex-flow: column;
  margin-top: 10px;
  align-items: center;
}
.questions {
  font-weight: bold;
  margin-bottom: 10px;
}
.question-btns {
  display: flex;
}

.send {
  margin: 0 10px;
}

.chat-area-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
}
.tools-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.chat-area {
  display: block;
  height: 600px;
  overflow: auto;
  border: 1px solid grey;
  border-radius: 5px;
  margin: 0;
  padding: 10px 5px;
  background-color: rgba(0, 0, 0, 0.1);
}
ul {
  list-style-type: none;
}

.active-users {
  display: inline;
}

.user {
  font-weight: bold;
  border-radius: 0.4em;
  clear: both;
  max-width: 80%;
}

.message {
  max-width: 100%;
  overflow-wrap: break-word;
  line-height: 1.4;
  margin-bottom: 5px;
}
.me {
  float: right;
}

.my-mess {
  color: rgb(102, 110, 193);
  float: right;
}
.partner-mess {
  float: left;
  color: rgb(255, 0, 162);
}
#leave-chatroom {
  width: 85px;
  height: 30px;
}

.input-flex-container {
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: space-between;
  margin: 20px 10px;
}
.write-message,
.message-btn {
  margin: 0;
  height: 30px;
}

@media only screen and (max-width: 600px) {
  .chat-area {
    margin: 0 10px;
  }
}

.random-room {
  margin-top: 20px;
}

.open-room {
  align-self: center;
  max-width: 100px;
  margin-top: 20px;
}

.dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
}

.dashboard-content {
  margin-top: 15px;
  margin-bottom: 20px;
  width: 90%;
  text-align: center;
  font-size: 1.5em;
}

.show{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:  100%;
  background-color: rgba(0,0,0,.85);
  cursor: pointer;
}
.overlay {
  position: absolute;
  padding: 10px;
  top: 30px;
  left: 25%;
  right: 25%;
  white-space: no-wrap;
  z-index: 1;
  color: rgb(168, 144, 184);
}
.hide{
  display: none;
}

.nav-bar {
  /* background: #838dfa; */
  /* padding: 10px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin: 0 10px;
}

.logout {
  border-radius: 100%;
  background-color: rgba(60, 88, 80);
  text-align: center;
  color: rgb(241, 184, 185);
  font-weight: bold;
  -webkit-font-feature-settings: "c2sc";
          font-feature-settings: "c2sc";
  font-variant: small-caps;
  padding: 18px 5px;
  width: 52px;
}

.navButton {
  background-color: rgba(131, 141, 250, 0);
  color: black;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border: 0px;
  margin: 10px 0;
  font-weight: bold;
  cursor: pointer;
}
.home {
  font-weight: bold;
}
.logo {
  height: 45px;
  color: rgb(0, 0, 0, 0);
  background-color: rgba(60, 88, 80);
  padding: 5px 0;
  /* border: 1px solid black; */
  border-radius: 100%;
}

#infoOverlay {
  background-color: rgba(60, 88, 80);
  text-align: center;
  color: rgb(241, 184, 185);
  font-weight: bold;
  -webkit-font-feature-settings: "c2sc";
          font-feature-settings: "c2sc";
  font-variant: small-caps;
  border-radius: 5px;
}

.form-input {
  font-size: 16px;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-spinner div {
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px;
  -webkit-animation: lds-spinner 1.2s linear infinite;
          animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 29px;
  width: 5px;
  height: 14px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.registration {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 auto 10px auto;
}

.register-btn {
  height: 30px;
  width: 80px;
  padding-top: 7px;
  margin: 0 5px;
}

.register-btn {
  justify-self: center;
}

label {
  align-self: flex-start;
  font-size: 16px;
}

.login-form {
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
  margin: 0 auto 10px auto;
}
label {
  align-self: flex-start;
  font-size: 16px;
  margin-top: 10px;
}
input {
  width: 100%;
  padding: 8px 20px;
  margin: 0 auto;
  border: 1px solid rgb(109, 109, 109);
  border-radius: 4px;
}
input[type='text'],
[type='password'] {
  font-size: 18px;
}

.login-btn {
  margin-top: 10px;
  align-self: center;
  width: 80px;
}
.lds-spinner {
  margin: 10px auto;
}

.title {
  text-align: center;
  color: rgb(116, 28, 33);
  font-weight: bold;
}

.register-btn {
  border-radius: 5px;
}

.description {
  width: 75%;
  margin: 0 auto 15px auto;
}

.content {
  text-align: center;
}

.auth-btn-container {
  display: flex;
  justify-content: center;
}

.auth-btn {
  height: 30px;
  width: 80px;
  padding-top: 7px;
  margin: 0 5px;
}

.box * {
  box-sizing: border-box;
  font-family: 'Josefin Sans';
}
body {
  background-color: rgb(241, 184, 185);
}
html,
body {
  height: 100%;
}

.container {
  display: flex;
  align-items: stretch;
  flex-flow: column nowrap;
  color: black;
  margin: 30px auto 10px auto;
  width: 70%;
  height: 100%;
  max-width: 650px;
  border-radius: 5px;
  background-color: rgb(243, 197, 198, 0);
  z-index: 0;
  /* border: 1px solid black; */
}

.background-image {
  background-image: url('/Users/misch/Thinkful/react-capstone/chat-date/public/squiggle.png');
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-repeat: repeat-x;
}

.button {
  background-color: rgba(60, 88, 80);
  box-shadow: #d4888b77;
  color: black;
  padding: 7px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 5px;
  color: rgb(241, 184, 185);
  font-weight: bold;
  -webkit-font-feature-settings: "c2sc";
          font-feature-settings: "c2sc";
  font-variant: small-caps;
  padding-top: 7px;
  /* margin: 0 5px; */
  /* font-family: 'Roboto'; */
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
    height: 40em;
    /* font-size: 30px; */
  }
}

