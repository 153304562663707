.registration {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 auto 10px auto;
}

.register-btn {
  height: 30px;
  width: 80px;
  padding-top: 7px;
  margin: 0 5px;
}

.register-btn {
  justify-self: center;
}

label {
  align-self: flex-start;
  font-size: 16px;
}
